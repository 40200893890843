import { createApp } from "vue";
import App from "./App.vue";
import { gsap } from "gsap";

createApp(App).mount("#app");

setTimeout(() => document.body.classList.add("render"), 60);

// Heartbeat
let heart = document.getElementById("heart");
const heartbeat = gsap.timeline({
  repeat: -1,
  yoyo: true,
});

heartbeat.to(heart, {
  duration: 2,
  scale: 1.25,
  ease: "elastic.out",
});

// Writing
let writing = document.querySelector("svg path#writing");
const writingAnim = gsap.timeline({
  repeat: -1,
  repeatDelay: 1,
  ease: "power4.out",
});
writingAnim.from(writing, {
  scaleX: 0,
  duration: 2,
});
writingAnim.to(writing, {
  delay: 2,
  opacity: 0,
  duration: 0.5,
});
